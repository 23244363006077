const footerLocalization = {
    footerTextTitle: {
        en: 'Welcome to Dsegh',
        hy: 'Բարի գալուստ  Դսեղ',
        ru: 'Добро пожаловать в Дсех'
    },
    footerText: {
        en: 'It is a place in Armenia that won’t leave you indifferent, where you will listen to all the sounds of nature and won’t get enough of the views, fragrances, and feelings.',
        hy: 'Սա մի վայր է Հայաստանում, որը Ձեզ չի թողնի անտարբեր, որտեղ Դուք կլսեք բնության բոլոր ձայները և չեք կարողանա հագենալ տեսարանից, բույրերից և զգացումներից։',
        ru: 'Это место в Армении, которое не оставит вас равнодушным, где вы будете слушать все звуки природы и не сможете насытиться от вида, ароматов и чувств.'
    },
    cycling: {
        en: 'Cycling',
        hy: 'Հեծանվավարություն',
        ru: 'Езда на велосипеде'
    },
    hiking: {
        en: 'Hiking',
        hy: 'Քայլարշավ',
        ru: 'Пеший поход'
    },
    camping: {
        en: 'Camping',
        hy: 'Քեմփինգ',
        ru: 'Кемпинг'
    },
    waterActivities: {
        en: 'Water activities',
        hy: 'Ջրային զբաղմունք',
        ru: 'Водные активности'
    },
    horsebackRiding: {
        en: 'Horseback riding',
        hy: 'Ձիարշավ',
        ru: 'Конный поход'
    },
    discoveringNature: {
        en: 'Discovering nature',
        hy: 'Բացահայտել բնությունը',
        ru: 'Знакомство с природой'
    },
    findUsInSocialNetwork: {
        en: 'Follow us in social network',
        hy: 'Հետևեք մեզ սոցիալական ցանցերում',
        ru: 'Следите за нами в соцсетях'
    },
    madeBy: {
        en: 'Made by',
        hy: 'Պատրաստված է',
        ru: 'Разработано'
    },
    allRightReserved: {
        en: 'All right reserved',
        hy: 'Բոլոր իրավունքները պաշտպանված են',
        ru: 'Все права защищены'
    }
}

export default footerLocalization