import React, { useEffect, useState } from "react";
import styles from '../Styles/ProgressiveImage.module.css'

const ProgressiveImage = (props) => {
  const [isLoaded, setIzLoaded] = useState(true);
  const [currentSrc, setCurrentSrc] = useState(props.placeholder);

  useEffect(() => {
    const imageToLoad = new Image();
    imageToLoad.src = props.src;
    imageToLoad.onload = () => {
      setIzLoaded(false);
      !isLoaded && setCurrentSrc(props.src);
    };
  });

  return (
    <img
      src={currentSrc}
      className={styles.progressiveImage}
      alt={props.alt}
    />
  );
};

export default ProgressiveImage;