import React, { useEffect, useState } from 'react';
import LangContext from '../Store/LangContext';
import styles from '../Styles/PageContentDescription.module.css';
import { nanoid } from 'nanoid';
import { loadContent } from '../Service/ApiService';
import LoadingSpinner from './Spinner/LoadingSpinner';

const PageContentDescription = (props) => {
  const [seoData, setSeoData] = useState([]);
  let id = props.id;
  const data = seoData?.find((item) => item.id === id);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('SeoContent', 'SeoContent');
      if (fetchData) setSeoData(fetchedData);
    };
    fetchData();
  }, []);

  if (data?.length === 0) return <LoadingSpinner />;

  const mainBody = (lang) => (
    <span>
      {data?.mainBody?.map((item) => {
        if (item.type === 'mainHeader') {
          return (
            <h2 className={styles.titleText} key={nanoid()}>
              {item.text[lang]}&nbsp;
            </h2>
          );
        } else if (item.type === 'secondaryHeader') {
          return (
            <h3 className={styles.titleText} key={nanoid()}>
              {item.text[lang]}&nbsp;
            </h3>
          );
        } else if (item.type === 'anchorText') {
          return (
            <a
              className={styles.anchorText}
              href={item.link[lang]}
              key={nanoid()}
            >
              {item.text[lang]}&nbsp;
            </a>
          );
        } else {
          return (
            <span className={styles.titleText} key={nanoid()}>
              {item.text[lang]} &nbsp;
            </span>
          );
        }
      })}
    </span>
  );

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.container}>
            <div className={styles.titleContainer}>
              <h2 className='text-sizing'>{data?.title[ctx.preferedLang]}</h2>
            </div>
            <div className={styles.blockContainer}>
              <div className={styles.descriptionContainer}>
                {mainBody(ctx.preferedLang)}
              </div>
            </div>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default PageContentDescription;
