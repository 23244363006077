import React from "react";
import styles from "./PageDetails.module.css";

const PageDescription = (props) => {
  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.environmentDescription}>
        {props.descriptionTitle}
        {props.description}
      </div>
      <div className={styles.activityInformation}>
        {props.informationTitle}
        {props.information}
      </div>
    </div>
  );
};

export default PageDescription;
