import React from "react";
import { Link } from "react-router-dom";
import LangContext from "../../Store/LangContext";
import navigationBarLocalization from "../../Localization/Navigation_Bar_Localization";
import styles from "../../Styles/DropdownItem.module.css";

const DropdownMenu = (props) => {
  const dropdownItemClickHandler = () => {
    props.setDropdownVisiable(false);
    props.setOpenedSidebar(false);
  };

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div
            className={
              props.dorpdawnVisiable
                ? styles.dropdownItemVisiable
                : styles.dropdownItemHidden
            }
          >
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdownLeftBlock}>
                <Link
                  to={`/${ctx.preferedLang}/history-and-culture`}
                  onClick={dropdownItemClickHandler}
                >
                  <p>
                    {navigationBarLocalization.historyAndCulture[
                      ctx.preferedLang
                    ][0].toUpperCase() +
                      navigationBarLocalization.historyAndCulture[
                        ctx.preferedLang
                      ]
                        .slice(1)
                        .toLowerCase()}
                  </p>
                </Link>
                <Link
                  to={`/${ctx.preferedLang}/sightseengs`}
                  onClick={dropdownItemClickHandler}
                >
                  <p>
                    {navigationBarLocalization.sightseeing[
                      ctx.preferedLang
                    ][0].toUpperCase() +
                      navigationBarLocalization.sightseeing[ctx.preferedLang]
                        .slice(1)
                        .toLowerCase()}
                  </p>
                </Link>
                <Link
                  to={`/${ctx.preferedLang}/events`}
                  onClick={dropdownItemClickHandler}
                >
                  <p>
                    {navigationBarLocalization.events[
                      ctx.preferedLang
                    ][0].toUpperCase() +
                      navigationBarLocalization.events[ctx.preferedLang]
                        .slice(1)
                        .toLowerCase()}
                  </p>
                </Link>
              </div>
              <div className={styles.dropdownRightBlock}>
                <Link
                  to={`/${ctx.preferedLang}/museums`}
                  onClick={dropdownItemClickHandler}
                >
                  <p>
                    {navigationBarLocalization.museums[
                      ctx.preferedLang
                    ][0].toUpperCase() +
                      navigationBarLocalization.museums[ctx.preferedLang]
                        .slice(1)
                        .toLowerCase()}
                  </p>
                </Link>
                <Link
                  to={`/${ctx.preferedLang}/where-to-stay`}
                  onClick={dropdownItemClickHandler}
                >
                  <p>
                    {navigationBarLocalization.placesToStary[
                      ctx.preferedLang
                    ][0].toUpperCase() +
                      navigationBarLocalization.placesToStary[ctx.preferedLang]
                        .slice(1)
                        .toLowerCase()}
                  </p>
                </Link>
              </div>
            </div>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default DropdownMenu;
