import React from "react";

const LangContext = React.createContext({
  preferedLang: Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.includes("Yerevan")
    ? "hy"
    : "en",
  setLanguage: (str) => {},
});

export default LangContext;
