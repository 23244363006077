import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DropdownMenu from './Navigation_Bar_Components/DropdownMenu';
import NavBarComponent from './Navigation_Bar_Components/NavBarComponent';
import LangContext from '../Store/LangContext';
import navigationBarLocalization from '../Localization/Navigation_Bar_Localization';
import ChooseLanguage from './Navigation_Bar_Components/ChooseLanguage';
import styles from '../Styles/NavigationBar.module.css';

const NavigationBar = () => {
  const [dorpdawnVisiable, setDropdownVisiable] = useState(false);
  const [openedSidebar, setOpenedSidebar] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [languagesVisiable, setLanguagesVisiable] = useState(false);

  const sidebarHandler = () => {
    setOpenedSidebar(!openedSidebar);
    setDropdownVisiable(false);
  };

  const setSidebarNotVisible = () => {
    setOpenedSidebar(false);
    setDropdownVisiable(false);
  };

  const sideSeengsDropdownHandler = () => {
    setDropdownVisiable(!dorpdawnVisiable);
  };

  const languageListHandler = () => {
    setLanguagesVisiable(!languagesVisiable);
  };

  useEffect(() => {
    if (width > 1200) {
      setOpenedSidebar(false);
    }
  }, [width]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    openedSidebar === true
      ? document.body.classList.add(`${styles.notScrolling}`)
      : document.body.classList.remove(`${styles.notScrolling}`);
  }, [openedSidebar]);

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <>
            <div className={styles.navbarBackground}>
              <div className={styles.burgerMenuContainer}>
                <button
                  onClick={sidebarHandler}
                  className={styles.burgerButton}
                >
                  {!openedSidebar ? (
                    <img
                      src='../../../Assets/Icons/Navigation_Bar/Burger_Menu.svg'
                      alt='burger menu icon'
                    />
                  ) : (
                    <img
                      src='../../../Assets/Icons/Navigation_Bar/Close.svg'
                      alt='close sidebar icon'
                    />
                  )}
                </button>
              </div>
              <div className={styles.navbarLogo} onClick={setSidebarNotVisible}>
                <Link to={`${ctx.preferedLang}`}>
                  <img
                    src='../../Assets/Images/Logotypes/Dsegh_Logo_Arm.png'
                    alt='dsegh.am logotype'
                    className={styles.logotype}
                  />
                </Link>
              </div>
              <div className={styles.navbarMenuContainer}>
                <ul className={styles.navbarComponents}>
                  <NavBarComponent
                    url={`/${ctx.preferedLang}/things-to-do`}
                    logo={
                      <img
                        src='../../../Assets/Icons/Navigation_Bar/To_Do.svg'
                        alt='what to do page icon'
                        className={styles.navbarIcons}
                      />
                    }
                  >
                    {navigationBarLocalization.thingsToDo[
                      ctx.preferedLang
                    ].toUpperCase()}
                  </NavBarComponent>
                  <NavBarComponent
                    url={`/${ctx.preferedLang}/gallery`}
                    logo={
                      <img
                        src='../../../Assets/Icons/Navigation_Bar/Gallery.svg'
                        alt='gallery icon'
                        className={styles.navbarIcons}
                      />
                    }
                  >
                    {navigationBarLocalization.gallery[
                      ctx.preferedLang
                    ].toUpperCase()}
                  </NavBarComponent>
                  <div
                    onClick={sideSeengsDropdownHandler}
                    onMouseEnter={() => setDropdownVisiable(true)}
                    onMouseLeave={() => setDropdownVisiable(false)}
                    className={styles.sideSeengs}
                  >
                    <li className={styles.dropdown}>
                      <img
                        src='../../../Assets/Icons/Navigation_Bar/Region_Information.svg'
                        alt='region information icon'
                        className={styles.navbarIcons}
                      />
                      <span>
                        {navigationBarLocalization.getToKnowTheCountry[
                          ctx.preferedLang
                        ].toUpperCase()}
                      </span>
                    </li>
                    <DropdownMenu
                      setDropdownVisiable={() => setDropdownVisiable()}
                      dorpdawnVisiable={dorpdawnVisiable}
                      setOpenedSidebar={setOpenedSidebar}
                    />
                  </div>
                </ul>
              </div>
              <div className={styles.profileContainer}>
                <div
                  onClick={languageListHandler}
                  onMouseEnter={() => setLanguagesVisiable(true)}
                  onMouseLeave={() => setLanguagesVisiable(false)}
                  className={styles.localizationSection}
                >
                  <div className={styles.dropdown}>
                    <div className={styles.localizationIconContainer}>
                      <img
                        src='../../../Assets/Icons/Translation/Language.svg'
                        className={styles.localizationIcon}
                        alt='language icon'
                      />
                    </div>
                  </div>
                  <ChooseLanguage
                    setLanguagesVisiable={setLanguagesVisiable}
                    languagesVisiable={languagesVisiable}
                    setOpenedSidebar={setOpenedSidebar}
                  />
                </div>
              </div>
            </div>
            {openedSidebar && (
              <>
                <div
                  className={styles.passiveBackground}
                  onClick={sidebarHandler}
                ></div>
                <div className={styles.burgerMenuOpened}>
                  <div className='row'>
                    <ul className='col-12 align-items-start gap-4 d-grid'>
                      <div onClick={sidebarHandler}>
                        <NavBarComponent
                          url={`/${ctx.preferedLang}/things-to-do`}
                          logo={
                            <img
                              src='../../../Assets/Icons/Navigation_Bar/To_Do.svg'
                              alt='what to do page icon'
                              className={styles.navbarIcons}
                            />
                          }
                        >
                          {navigationBarLocalization.thingsToDo[
                            ctx.preferedLang
                          ].toUpperCase()}
                        </NavBarComponent>
                      </div>
                      <div onClick={sidebarHandler}>
                        <NavBarComponent
                          url={`/${ctx.preferedLang}/gallery`}
                          logo={
                            <img
                              src='../../../Assets/Icons/Navigation_Bar/Gallery.svg'
                              alt='gallery icon'
                              className={styles.navbarIcons}
                            />
                          }
                        >
                          {navigationBarLocalization.gallery[
                            ctx.preferedLang
                          ].toUpperCase()}
                        </NavBarComponent>
                      </div>
                      <button
                        onClick={sideSeengsDropdownHandler}
                        className={styles.sideSeengs}
                      >
                        <img
                          src='../../../Assets/Icons/Navigation_Bar/Region_Information.svg'
                          alt='region information icon'
                          className={styles.navbarIcons}
                        />
                        <span>
                          {navigationBarLocalization.getToKnowTheCountry[
                            ctx.preferedLang
                          ].toUpperCase()}
                        </span>
                        <DropdownMenu
                          setDropdownVisiable={() => setDropdownVisiable()}
                          dorpdawnVisiable={dorpdawnVisiable}
                          onClick={sidebarHandler}
                        />
                      </button>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </>
        );
      }}
    </LangContext.Consumer>
  );
};

export default NavigationBar;
