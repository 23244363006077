import React, { useEffect, useState } from 'react';
import keyWordsLocalization from '../../Localization/Key_Words';
import { Link } from 'react-router-dom';
import LangContext from '../../Store/LangContext';
import ProgressiveImage from '../ProgressiveImage';
import styles from '../../Styles/EventsList.module.css';
import { loadContent } from '../../Service/ApiService';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const EventsList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('Events', 'eventsData');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (data?.length === 0) return <LoadingSpinner />;

  const eventsCardBody = (lang) =>
    data?.map((event) => {
      return (
        <div className={styles.card} key={event.id}>
          <div className={styles.imageContainer}>
            <ProgressiveImage
              src={event.cardImage}
              placeholder={event.cardImage}
            />
          </div>
          <div className={styles.textInformationBlock}>
            <div className={styles.titleContainer}>
              <h1>{event.title[lang]}</h1>
            </div>
            <div className={styles.informationContainer}>
              <div className={styles.textBlocks}>
                <img
                  src='../../Assets/Icons/Events/Festival.svg'
                  alt='Festival icon'
                  className={styles.icon}
                />
                <span className={styles.secondaryText}>{event.type[lang]}</span>
              </div>
              <div className={styles.textBlocks}>
                <img
                  src='../../Assets/Icons/Events/Location.svg'
                  alt='Location icon'
                  className={styles.icon}
                />
                <span className={styles.secondaryText}>
                  {event.location[lang]}
                </span>
              </div>
              <div className={styles.textBlocks}>
                <img
                  src='../../Assets/Icons/Events/Price.svg'
                  alt='Price icon'
                  className={styles.icon}
                />
                <span className={styles.secondaryText}>
                  {event.billable[lang]}
                </span>
              </div>
              <div className={styles.textContainer}>
                <span>{event.description[lang]}</span>
              </div>
            </div>
            <Link to={`/${lang}/` + event.id} className={styles.button}>
              {keyWordsLocalization.exploreMore[lang]}
            </Link>
          </div>
        </div>
      );
    });

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.container}>
            {eventsCardBody(ctx.preferedLang)}
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default EventsList;
