import React from 'react';
import LangContext from '../../Store/LangContext';
import HomePageActivitiesList from './HomePageActivitiesList';
import { nanoid } from 'nanoid';
import styles from '../../Styles/Home.module.css';

const HomePageActivities = (props) => {
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.activitiesContainer}>
            <div className={styles.blockTitleContainer}>
              <div className={styles.activityBlockDescriptionContainer}>
                <div>
                  {props.data?.activitiesTitle?.map((item) => {
                    if (item.type === 'mainTitle') {
                      return (
                        <h1 className={styles.blockTitle} key={nanoid()}>
                          {item.text[ctx.preferedLang]}
                        </h1>
                      );
                    } else if (item.type === 'secondaryTitle') {
                      return (
                        <h2 className={styles.blockTitle} key={nanoid()}>
                          {item.text[ctx.preferedLang]}
                        </h2>
                      );
                    } else {
                      return (
                        <span className={styles.blockTitle} key={nanoid()}>
                          {item.text[ctx.preferedLang]}
                        </span>
                      );
                    }
                  })}
                </div>
                <br />
                <div className={styles.activityTextContainer}>
                  {props.data.activities?.map((item) => {
                    if (item.type === 'mainTitle') {
                      return (
                        <h1 className={styles.shortInformation} key={nanoid()}>
                          {item.text[ctx.preferedLang]}
                        </h1>
                      );
                    } else if (item.type === 'secondaryTitle') {
                      return (
                        <h2 className={styles.shortInformation} key={nanoid()}>
                          {item.text[ctx.preferedLang]}
                        </h2>
                      );
                    } else {
                      return (
                        <span
                          className={styles.shortInformation}
                          key={nanoid()}
                        >
                          {item.text[ctx.preferedLang]}
                        </span>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div className={styles.activitiesList}>
              <HomePageActivitiesList />
            </div>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default HomePageActivities;
