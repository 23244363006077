import React from "react";
import { Link } from "react-router-dom";
import keyWordsLocalization from "../../Localization/Key_Words";
import LangContext from "../../Store/LangContext";
import styles from "../../Styles/WhereToStay.module.css";

const HomePageHotels = (props) => {
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.whereToStayContainer}>
            <div className={styles.toStayTextBlock}>
              <span className={styles.toStayText}>{props.children}</span>
              <Link
                to={`/${ctx.preferedLang}/where-to-stay`}
                className={styles.readMore}
              >
                {keyWordsLocalization.exploreMore[ctx.preferedLang]}
              </Link>
            </div>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default HomePageHotels;
