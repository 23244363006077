import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import LangContext from '../../Store/LangContext';
import keyWordsLocalization from '../../Localization/Key_Words';
import Cookies from 'js-cookie';
import '../../App.css';

import styles from '../../Styles/To_Do_Pages.module.css';
import PanoramaView from '../../Components/PanoramaView';
import ProgressiveImage from '../../Components/ProgressiveImage';
import { loadContent } from '../../Service/ApiService';
import LoadingSpinner from '../../Components/Spinner/LoadingSpinner';

const Gallery = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('GalleryData', 'GalleryData');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  const lng = Cookies.get('preferedLanguage');
  useEffect(() => {
    document.title =
      lng === 'hy'
        ? keyWordsLocalization.gallery.hy.toUpperCase()
        : lng === 'ru'
        ? keyWordsLocalization.gallery.ru.toUpperCase()
        : keyWordsLocalization.gallery.en.toUpperCase();
  }, [lng]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const imageArray = [];
  data?.images?.map((image) => {
    return imageArray.push({
      original: image.source,
      thumbnail: image.source,
    });
  });

  const properties = {
    thumbnailPosition: width <= 600 ? 'bottom' : 'left',
    showNav: false,
    lazyLoad: 'true',
    useBrowserFullscreen: true,
    showPlayButton: false,
    items: imageArray,
    disableThumbnailScroll: true,
  };

  if (Object.keys(data)?.length === 0) return <LoadingSpinner />;

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.container}>
            <div className={styles.backgroundImage}>
              <ProgressiveImage
                src='../../Assets/Images/Pages_Backgrounds/Image_Gallery_Background_High.webp'
                placeholder='../../Assets/Images/Pages_Backgrounds/Image_Gallery_Background_Low.webp'
                className={styles.coverImage}
              />
              <div className={styles.titleContainer}>
                <h1>
                  {keyWordsLocalization.gallery[ctx.preferedLang].toUpperCase()}
                </h1>
              </div>
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.blockContainer}>
                <div className={styles.contentTitleContainer}>
                  <span>
                    {keyWordsLocalization.galleryTitle[ctx.preferedLang]}
                  </span>
                </div>
                <div className={styles.galleryImagesContainer}>
                  <ImageGallery lazyLoad={true} {...properties} />
                </div>
                <div className={styles.galleryImagesContainer}>
                  <PanoramaView data={data} />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default Gallery;
