import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import styles from './ThingsToDoDetails.module.css';
import LangContext from '../../Store/LangContext';

const HouseMuseum = (props) => {
  const { id } = useParams();
  const toDoData = props.data?.find((item) => item.id === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const houseMuseumBody = (lang) => (
    <>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={toDoData.icons[0]}
            className={styles.generalInformationIcon}
            alt='Phone'
          />
          <span className={styles.generalInformationName}>
            {toDoData.informationTitles[0][lang]}
          </span>
        </div>
        <a className={styles.generalInformation} href={`tel:${toDoData.phone}`}>
          {toDoData.phone}
        </a>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={toDoData.icons[1]}
            className={styles.generalInformationIcon}
            alt='Email'
          />
          <span className={styles.generalInformationName}>
            {toDoData.informationTitles[1][lang]}
          </span>
        </div>
        <a
          className={styles.generalInformation}
          href={`mailto:${toDoData.eMail}`}
        >
          {toDoData.eMail}
        </a>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={toDoData.icons[2]}
            className={styles.generalInformationIcon}
            alt='Is open on'
          />
          <span className={styles.generalInformationName}>
            {toDoData.informationTitles[2][lang]}
          </span>
        </div>
        <span className={styles.generalInformation}>
          {toDoData.opened[lang]}
        </span>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={toDoData.icons[3]}
            className={styles.generalInformationIcon}
            alt='Is close on'
          />
          <span className={styles.generalInformationName}>
            {toDoData.informationTitles[3][lang]}
          </span>
        </div>
        <span className={styles.generalInformation}>
          {toDoData.closed[lang]}
        </span>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={toDoData.icons[4]}
            className={styles.generalInformationIcon}
            alt='Ticket fee'
          />
          <span className={styles.generalInformationName}>
            {toDoData.informationTitles[4][lang]}
          </span>
        </div>
        <span className={styles.generalInformation}>
          {toDoData.price.adults}, {toDoData.price.kids}
        </span>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={toDoData.icons[5]}
            className={styles.generalInformationIcon}
            alt='Guide fee'
          />
          <span className={styles.generalInformationName}>
            {toDoData.informationTitles[5][lang]}
          </span>
        </div>
        <span className={styles.generalInformation}>
          {toDoData.guide.armenian}, {toDoData.guide.russian}
        </span>
      </div>
    </>
  );

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return houseMuseumBody(ctx.preferedLang);
      }}
    </LangContext.Consumer>
  );
};

export default HouseMuseum;
