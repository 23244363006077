import React from "react";
import styles from "../Styles/PageNotFound.module.css";

const PageNotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.pageTextContainer}>
        <h3 className={styles.pageTexts}>OOPS! PAGE NOT FOUND</h3>
        <h1>
          <span className={styles.notFoundText}>4</span>
          <span className={styles.notFoundText}>0</span>
          <span className={styles.notFoundText}>4</span>
        </h1>
        <h3 className={styles.pageTexts}>
          WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND
        </h3>
      </div>
    </div>
  );
};

export default PageNotFound;
