import React from 'react';
import { Pannellum } from 'pannellum-react';
import styles from '../Styles/PanoramaView.module.css';
import { nanoid } from 'nanoid';

const PanoramaView = (props) => {
  return (
    <div className={styles.container}>
      {props.data?.panorama?.map((panoramaImage) => {
        return (
          <div key={nanoid()} className={styles.panoramaViewContainer}>
            <Pannellum
              width='100%'
              height='200px'
              image={panoramaImage.source}
              pitch={10}
              yaw={180}
              hfov={110}
              autoLoad
              showZoomCtrl={false}
              mouseZoom={false}
              onLoad={() => {}}
              key={panoramaImage.id}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PanoramaView;
