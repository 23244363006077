import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaTitle = (props) => {
  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>{props.title}</title>
        <meta name={props.name} content={props.content} />
        {props.isMAinPage && <link rel={props.rel} href={props.pageURL} />}
        {props.preload && (
          <link
            rel='preload'
            fetchpriority='high'
            as='image'
            href={props.imageUrl}
            type='image/webp'
          />
        )}
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaTitle;
