import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
} from "react-share";
import keyWordsLocalization from "../../Localization/Key_Words";
import LangContext from "../../Store/LangContext";
import styles from "./PageDetails.module.css";

const SharePageInSocialMedia = (props) => {
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.socialMediaBlock}>
            <span>{keyWordsLocalization.shareThisPage[ctx.preferedLang]}:</span>
            <FacebookShareButton
              url={props.pageUrl}
              quote={""}
              hashtag={"#visit_Dsegh"}
              description={""}
            >
              <img src="../../Assets/Icons/House_Museum/Facebook.svg" alt="Facebook icon" />
            </FacebookShareButton>
            <TwitterShareButton
              url={props.pageUrl}
              quote={""}
              hashtag={"#visit_Dsegh"}
              description={""}
            >
              {" "}
              <img src="../../Assets/Icons/House_Museum/Twitter.svg" alt="Twitter icon" />
            </TwitterShareButton>
            <InstapaperShareButton
              url={props.pageUrl}
              quote={""}
              hashtag={"#visit_Dsegh"}
              description={""}
            >
              {" "}
              <img src="../../Assets/Icons/House_Museum/Instagram.svg" alt="Instagram icon" />
            </InstapaperShareButton>
            <LinkedinShareButton
              url={props.pageUrl}
              quote={""}
              hashtag={"#visit_Dsegh"}
              description={""}
            >
              {" "}
              <img src="../../Assets/Icons/House_Museum/LinkedIn.svg" alt="LinkedIn icon" />
            </LinkedinShareButton>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default SharePageInSocialMedia;