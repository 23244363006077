import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import keyWordsLocalization from '../../Localization/Key_Words';
import LangContext from '../../Store/LangContext';
import styles from '../../Styles/PlacesToVisitList.module.css';
import ProgressiveImage from '../ProgressiveImage';
import { loadContent } from '../../Service/ApiService';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const PlacesToVisitList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent(
        'PlacesToVisit',
        'placesToVisitData'
      );
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (data?.length === 0) return <LoadingSpinner />;

  const placesBody = (lang) =>
    data?.map((place) => {
      return (
        <div className={styles.card} key={place.id}>
          <div className={styles.imageContainer}>
            <ProgressiveImage
              src={place.cardImage}
              placeholder={place.cardImage}
            />
          </div>
          <div className={styles.textInformationBlock}>
            <div className={styles.titleContainer}>
              <h1>{place.title[lang]}</h1>
            </div>
            <div className={styles.textContainer}>
              <span>{place.description[lang]}</span>
            </div>
            <Link to={`/${lang}/` + place.id} className={styles.button}>
              {keyWordsLocalization.exploreMore[lang]}
            </Link>
          </div>
        </div>
      );
    });

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.container}>{placesBody(ctx.preferedLang)}</div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default PlacesToVisitList;
