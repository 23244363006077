import React, { useState } from 'react';
import CustomModal from '../CustomModal';
import GoogleMaps from '../GoogleMaps';
import keyWordsLocalization from '../../Localization/Key_Words';
import styles from './PageDetails.module.css';
import LangContext from '../../Store/LangContext';
import { Marker } from '@react-google-maps/api';

const PageLocationOnMap = () => {
  const [mapIsShown, setMapIsShown] = useState(false);
  const handleOnLoad = (map) => {
    map.setZoom(15);
  };
  const defaultCoordinates = {
    position: { lat: 40.957996168, lng: 44.650997396 },
    id: 1,
    name: 'Dsegh, Armenia',
  };

  const mapStyles = {
    width: '100%',
    height: '600px',
  };

  const locationOnmapBody = (lang) => (
    <>
      <div className={styles.mapContainer}>
        <button
          onClick={() => setMapIsShown(true)}
          className={styles.mapButton}
        >
          <img
            src='../../Assets/Icons/House_Museum/Location.svg'
            alt='Location icon'
            className={styles.locationIcon}
          />
          {keyWordsLocalization.location[lang]}
        </button>
      </div>
      <CustomModal
        show={mapIsShown}
        onHide={() => setMapIsShown(false)}
        modalWidth='modal-dialog modal-lg'
      >
        <GoogleMaps
          onLoad={handleOnLoad}
          mapContainerStyle={mapStyles}
          zoom={15}
          center={defaultCoordinates.position}
        >
          <Marker
            key={defaultCoordinates.id}
            position={defaultCoordinates.position}
          />
        </GoogleMaps>
      </CustomModal>
    </>
  );

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return locationOnmapBody(ctx.preferedLang);
      }}
    </LangContext.Consumer>
  );
};

export default PageLocationOnMap;
