export const SchemaMarkup = {
  en: {
    "@context": "https://schema.org/",
    "@type": "LocalBusiness",
    name: "Dsegh",
    address: "Dsegh village",
    image:
      "https://dsegh.am/Assets/Images/Container_Background/Home_Background_High_Quality.webp",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "1",
      ratingCount: "1",
      reviewCount: "1",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "40.95909251266096",
      longitude: "44.64873779618999",
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      validFrom: "2022-01-01T00:00:00.000Z",
      validThrough: "3022-01-01T00:00:00.000Z",
      opens: "09:00",
      closes: "09:00",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    },
    telephone: "-",
    priceRange: "-",
    department: [
      {
        "@type": "LocalBusiness",
        name: "Dsegh",
        address: "Dsegh village",
        image: "https://dsegh.am/Assets/Images/Attractions/LakeTsover.webp",
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "1",
          ratingCount: "1",
          reviewCount: "1",
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: "40.96064811262009",
          longitude: "44.65045440984738",
        },
        openingHoursSpecification: {
          "@type": "OpeningHoursSpecification",
          validFrom: "2022-01-01T00:00:00.000Z",
          validThrough: "3022-01-01T00:00:00.000Z",
          opens: "09:00",
          closes: "09:00",
          dayOfWeek: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
        },
        telephone: "-",
        priceRange: "-",
        menu: "https://dsegh.am/en",
        servesCuisine: "WHAT TO DO in Dsegh?",
        url: "https://dsegh.am/en/things-to-do",
      },
    ],
    menu: "https://dsegh.am/en",
    servesCuisine: "WHAT TO DO in Dsegh?",
    url: "https://dsegh.am/en/things-to-do",
  },
  hy: {
    "@context": "https://schema.org/",
    "@type": "LocalBusiness",
    name: "Դսեղ",
    address: "գյուղ Դսեղ",
    image:
      "https://dsegh.am/Assets/Images/Container_Background/Home_Background_High_Quality.webp",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "1",
      ratingCount: "1",
      reviewCount: "1",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "40.95909251266096",
      longitude: "44.64873779618999",
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      validFrom: "2022-01-01T00:00:00.000Z",
      validThrough: "3022-01-01T00:00:00.000Z",
      opens: "09:00",
      closes: "09:00",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    },
    telephone: "-",
    priceRange: "-",
    department: [
      {
        "@type": "LocalBusiness",
        name: "Դսեղ",
        address: "գյուղ Դսեղ",
        image: "https://dsegh.am/Assets/Images/Attractions/LakeTsover.webp",
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "1",
          ratingCount: "1",
          reviewCount: "1",
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: "40.96064811262009",
          longitude: "44.65045440984738",
        },
        openingHoursSpecification: {
          "@type": "OpeningHoursSpecification",
          validFrom: "2022-01-01T00:00:00.000Z",
          validThrough: "3022-01-01T00:00:00.000Z",
          opens: "09:00",
          closes: "09:00",
          dayOfWeek: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
        },
        telephone: "-",
        priceRange: "-",
        menu: "https://dsegh.am/hy",
        servesCuisine: "ԻՆՉՈ՞Վ ԶԲԱՂՎԵԼ ԴՍԵՂՈՒՄ",
        url: "https://dsegh.am/hy/things-to-do",
      },
    ],
    menu: "https://dsegh.am/hy",
    servesCuisine: "ԻՆՉՈ՞Վ ԶԲԱՂՎԵԼ ԴՍԵՂՈՒՄ",
    url: "https://dsegh.am/hy/things-to-do",
  },
  ru: {
    "@context": "https://schema.org/",
    "@type": "LocalBusiness",
    name: "Дсех",
    address: "Село Дсех",
    image:
      "https://dsegh.am/Assets/Images/Container_Background/Home_Background_High_Quality.webp",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "1",
      ratingCount: "1",
      reviewCount: "1",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "40.95909251266096",
      longitude: "44.64873779618999",
    },
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      validFrom: "2022-01-01T00:00:00.000Z",
      validThrough: "3022-01-01T00:00:00.000Z",
      opens: "09:00",
      closes: "09:00",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    },
    telephone: "-",
    priceRange: "-",
    department: [
      {
        "@type": "LocalBusiness",
        name: "Дсех",
        address: "Село Дсех",
        image: "https://dsegh.am/Assets/Images/Attractions/LakeTsover.webp",
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: "1",
          ratingCount: "1",
          reviewCount: "1",
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: "40.96064811262009",
          longitude: "44.65045440984738",
        },
        openingHoursSpecification: {
          "@type": "OpeningHoursSpecification",
          validFrom: "2022-01-01T00:00:00.000Z",
          validThrough: "3022-01-01T00:00:00.000Z",
          opens: "09:00",
          closes: "09:00",
          dayOfWeek: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ],
        },
        telephone: "-",
        priceRange: "-",
        menu: "https://dsegh.am/ru",
        servesCuisine: "ЧЕМ ЗАНЯТЬСЯ в Дсеге",
        url: "https://dsegh.am/ru/things-to-do",
      },
    ],
    menu: "https://dsegh.am/ru",
    servesCuisine: "ЧЕМ ЗАНЯТЬСЯ в Дсеге",
    url: "https://dsegh.am/ru/things-to-do",
  },
};
