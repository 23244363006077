const navigationBarLocalization = {
    thingsToDo: {
        en: 'what to do',
        hy: 'ինչով զբաղվել',
        ru: 'чем заняться'
    },
    placesToStary: {
        en: 'Where to stay',
        hy: 'ամրագրել',
        ru: 'где переночевать'
    },
    getToKnowTheCountry: {
        en: 'discover Dsegh',
        hy: 'բացահայտել Դսեղը',
        ru: 'знакомство с Дсехом'
    },
    profile:     {
        en: 'profile',
        hy: 'անձնական էջ',
        ru: 'профиль'
    },
    historyAndCulture: {
        en: 'history and culture',
        hy: 'պատմություն և մշակույթ',
        ru: 'история и культура'
    },
    sightseeing:     {
        en: 'sightseeings',
        hy: 'տեսարժան վայրեր',
        ru: 'достопримечательности'
    },
    museums:     {
        en: 'museums',
        hy: 'թանգարաններ',
        ru: 'музеи'
    },
    gallery:     {
        en: 'gallery',
        hy: 'տեսադարան',
        ru: 'галлерея'
    },
    events: {
        en: 'events',
        hy: 'Միջոցառումներ',
        ru: 'Мероприятия'
    }
}

export default navigationBarLocalization