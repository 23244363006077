import React, { useState, useEffect, Suspense } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import NavigationBar from './Components/NavigationBar';
import LangContext from './Store/LangContext';
import Footer from './Components/Footer';
import { SchemaMarkup } from './Store/SchemaMarkup';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { loadContent } from './Service/ApiService';
import LoadingSpinner from './Components/Spinner/LoadingSpinner';
import Home from './Containers/Home';
import Gallery from './Pages/Get_To_Know_The_Country/Gallery';
import ToDoPages from './Pages/Things_To_Do/ToDoPages';
import PageNotFound from './Pages/PageNotFound';
import ThingsToDo from './Containers/ThingsToDo';
import PlacesToStay from './Containers/PlacesToStay';
import HistoryAndCulture from './Pages/Get_To_Know_The_Country/HistoryAndCulture';
import Events from './Pages/Get_To_Know_The_Country/Events';
import Sightseengs from './Pages/Get_To_Know_The_Country/Sightseengs';
import Museums from './Pages/Get_To_Know_The_Country/Museums';

function App() {
  const [data, setData] = useState([]);
  // const pathFromArmenia = window.location.pathname.replace(/\/en|\/ru/, "/hy");
  let prefLang =
    Cookies.get('preferedLanguage') === 'hy'
      ? 'hy'
      : Cookies.get('preferedLanguage') === 'ru'
      ? 'ru'
      : 'en';
  const [preferedLang, setLanguage] = useState(prefLang);
  // if (
  //   !Cookies.get("preferedLanguage") &&
  //   Intl.DateTimeFormat()
  //     .resolvedOptions()
  //     .timeZone.toString()
  //     .includes("Yerevan") &&
  //   (window.location.pathname === "/en" || "/ru")
  // ) {
  //   Cookies.set("preferedLanguage", "hy");
  //   window.location.replace(pathFromArmenia);
  // }
  if (
    Cookies.get('preferedLanguage') !== 'en' &&
    window.location.pathname.includes('/en')
  ) {
    Cookies.set('preferedLanguage', 'en');
    prefLang = 'en';
    window.location.href.replace(/\/en|\/hy|\/ru/, 'en');
  } else if (
    Cookies.get('preferedLanguage') !== 'ru' &&
    window.location.pathname.includes('/ru')
  ) {
    Cookies.set('preferedLanguage', 'ru');
    prefLang = 'ru';
    window.location.href.replace(/\/en|\/hy|\/ru/, 'ru');
  } else if (
    Cookies.get('preferedLanguage') !== 'hy' &&
    window.location.pathname.includes('/hy')
  ) {
    Cookies.set('preferedLanguage', 'hy');
    prefLang = 'hy';
    window.location.href.replace(/\/en|\/hy|\/ru/, 'hy');
  }
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('AvailableRouts', 'AvailableRouts');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname.includes('sitemap_index.xml'))
      window.location.replace(`${window.location.origin}/sitemap.xml`);
  }, []);

  const newUrl = window.location.pathname.replace(/\/en|\/hy|\/ru/, '');
  useEffect(() => {
    if (data?.length > 0 && !data?.includes(newUrl.toString())) {
      navigate(`/${prefLang}/page-not-found`, { replace: true });
    }
  }, [newUrl, prefLang, data, navigate]);

  if (data?.length === 0) return <LoadingSpinner />;

  return (
    <div className='App'>
      <script className='structured-data-list' type='application/ld+json'>
        {JSON.stringify(SchemaMarkup[prefLang])}
      </script>
      <LangContext.Provider
        value={{
          preferedLang,
          setLanguage,
        }}
      >
        <Suspense fallback={<LoadingSpinner />}>
          <div className='appBodyContainer'>
            <NavigationBar />
            <Routes>
              <Route exact path={`/${prefLang}`} element={<Home />} />
              <Route
                exact
                path={'/'}
                element={<Navigate replace to={`/${prefLang}`} />}
              />
              <Route
                path={`/${prefLang}/things-to-do`}
                element={<ThingsToDo />}
              />
              <Route
                path={`/${prefLang}/where-to-stay`}
                element={<PlacesToStay />}
              />
              <Route
                path={`/${prefLang}/history-and-culture`}
                element={<HistoryAndCulture />}
              />
              <Route
                path={`/${prefLang}/sightseengs`}
                element={<Sightseengs />}
              />
              <Route path={`/${prefLang}/gallery`} element={<Gallery />} />
              <Route exact path={`/${prefLang}/events`} element={<Events />} />
              <Route path={`/${prefLang}/museums`} element={<Museums />} />
              <Route
                path={`/${prefLang}/page-not-found`}
                element={<PageNotFound />}
              />
              <Route
                path={`/${prefLang}/:id`}
                element={<ToDoPages routs={data} />}
              />
            </Routes>
          </div>
          <Footer />
        </Suspense>
      </LangContext.Provider>
    </div>
  );
}

export default App;
