import React from "react";
import ImageSlider from "../Carousel/ImageSlider";

const HotelCardSlider = (props) => {
  return (
    <ImageSlider
      showStatus={false}
      showThumbs={false}
      showIndicators={true}
      showArrows={false}
      infiniteLoop={true}
    >
      {props.children}
    </ImageSlider>
  );
};

export default HotelCardSlider;
