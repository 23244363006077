import React, { useEffect, useState } from 'react';
import keyWordsLocalization from '../../Localization/Key_Words';
import { Link } from 'react-router-dom';
import LangContext from '../../Store/LangContext';
import styles from '../../Styles/MuseumsList.module.css';
import { loadContent } from '../../Service/ApiService';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const MuseumsList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('Museums', 'museumsData');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (data?.length === 0) return <LoadingSpinner />;

  const museumCardBody = (lang) =>
    data?.map((museum) => {
      return (
        <div className={styles.componentContainer} key={museum.id}>
          <div className={styles.imageContainer}>
            <img
              src={museum.cardImage}
              alt={museum.title['en']}
              className={styles.image}
            />
          </div>
          <div className={styles.informationContainer}>
            <div className={styles.titleContainer}>
              <span>{museum.title[lang]}</span>
            </div>
            <div className={styles.textBlocks}>
              <img
                src='../../Assets/Icons/House_Museum/Location.svg'
                alt='Location icon'
                className={styles.icon}
              />
              <span>{museum.addresse[lang]}</span>
            </div>
            <div className={styles.textBlocks}>
              <img
                src='../../Assets/Icons/House_Museum/Working.svg'
                alt='Working hours icon'
                className={styles.icon}
              />
              <span>{museum.opened[lang]}</span>
            </div>
            <div className={styles.textBlocks}>
              <img
                src='../../Assets/Icons/House_Museum/NonWorking.svg'
                alt='Non working hours icon'
                className={styles.icon}
              />
              <span>{museum.closed[lang]}</span>
            </div>
            <div className={styles.buttonContainer}>
              <Link to={`/${lang}/` + museum.id} className={styles.button}>
                {keyWordsLocalization.exploreMore[lang]}
              </Link>
            </div>
          </div>
        </div>
      );
    });

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.container}>
            {museumCardBody(ctx.preferedLang)}
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default MuseumsList;
