import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ThingsToDoDetails.module.css';
import LangContext from '../../Store/LangContext';

const EventsDetails = (props) => {
  const { id } = useParams();
  const eventData = props.data?.find((event) => event.id === id);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const eventsDetailsBody = (lang) => (
    <>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={eventData.icons[1]}
            className={styles.generalInformationIcon}
            alt='Type'
          />
          <span className={styles.generalInformationName}>
            {eventData.informationTitles[1][lang]}
          </span>
        </div>
        <span className={styles.generalInformation}>
          {eventData.type[lang]}
        </span>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={eventData.icons[2]}
            className={styles.generalInformationIcon}
            alt='Holds on'
          />
          <span className={styles.generalInformationName}>
            {eventData.informationTitles[2][lang]}
          </span>
        </div>
        <span className={styles.generalInformation}>
          {eventData.carriingOut[lang]}
        </span>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={eventData.icons[3]}
            className={styles.generalInformationIcon}
            alt='Duration'
          />
          <span className={styles.generalInformationName}>
            {eventData.informationTitles[3][lang]}
          </span>
        </div>
        <span className={styles.generalInformation}>
          {eventData.duration[lang]}
        </span>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={eventData.icons[4]}
            className={styles.generalInformationIcon}
            alt='Partisipation'
          />
          <span className={styles.generalInformationName}>
            {eventData.informationTitles[4][lang]}
          </span>
        </div>
        <span className={styles.generalInformation}>
          {eventData.billable[lang]}
        </span>
      </div>
    </>
  );

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return eventsDetailsBody(ctx.preferedLang);
      }}
    </LangContext.Consumer>
  );
};

export default EventsDetails;
