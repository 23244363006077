import React from 'react';
import PlaceSuggesionCards from './PlaceSuggesionCards';
import keyWordsLocalization from '../../Localization/Key_Words';
import LangContext from '../../Store/LangContext';
import { nanoid } from 'nanoid';
import styles from '../../Styles/Home.module.css';

const HomePageSuggestions = (props) => {
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.placeSuggestionContainer}>
            <div className={styles.blockTitleContainer}>
              {props.data.adventuresTitle?.map((item) => {
                if (item.type === 'mainTitle') {
                  return (
                    <h1 className={styles.blockTitle} key={nanoid()}>
                      {item.text[ctx.preferedLang]}
                    </h1>
                  );
                } else if (item.type === 'secondaryTitle') {
                  return (
                    <h2 className={styles.blockTitle} key={nanoid()}>
                      {item.text[ctx.preferedLang]}
                    </h2>
                  );
                } else {
                  return (
                    <span className={styles.blockTitle} key={nanoid()}>
                      {item.text[ctx.preferedLang]}
                    </span>
                  );
                }
              })}
              {props.data.adventures?.map((item) => {
                if (item.type === 'mainTitle') {
                  return (
                    <h1 className={styles.shortInformation} key={nanoid()}>
                      {item.text[ctx.preferedLang]}
                    </h1>
                  );
                } else if (item.type === 'secondaryTitle') {
                  return (
                    <h2 className={styles.shortInformation} key={nanoid()}>
                      {item.text[ctx.preferedLang]}
                    </h2>
                  );
                } else {
                  return (
                    <span className={styles.shortInformation} key={nanoid()}>
                      {item.text[ctx.preferedLang]}
                    </span>
                  );
                }
              })}
            </div>
            <PlaceSuggesionCards
              url={`/${ctx.preferedLang}/events`}
              backgroundImage='Assets/Images/Place_Offers/Events.webp'
              backgroundVideo='Assets/Videos/Events.mp4'
            >
              {keyWordsLocalization.events[ctx.preferedLang]}
            </PlaceSuggesionCards>
            <PlaceSuggesionCards
              url={`/${ctx.preferedLang}/sightseengs`}
              backgroundImage='Assets/Images/Place_Offers/Sightseeing.webp'
              backgroundVideo='Assets/Videos/Sightseengs.mp4'
            >
              {keyWordsLocalization.sightseeings[ctx.preferedLang]}
            </PlaceSuggesionCards>
            <PlaceSuggesionCards
              url={`/${ctx.preferedLang}/museums`}
              backgroundImage='Assets/Images/Place_Offers/Museums.webp'
              backgroundVideo='Assets/Videos/House_Museum.mp4'
            >
              {keyWordsLocalization.museums[ctx.preferedLang]}
            </PlaceSuggesionCards>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default HomePageSuggestions;
