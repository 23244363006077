import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import keyWordsLocalization from '../../Localization/Key_Words';
import LangContext from '../../Store/LangContext';
import styles from '../../Styles/Attractions.module.css';
import { loadContent } from '../../Service/ApiService';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const HomePageAttractionsList = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('Attractions', 'AttractionsData');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  if (data?.length === 0) return <LoadingSpinner />;

  return (
    <LangContext.Consumer>
      {(ctx) => (
        <div className={styles.attractionsContainer}>
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop
            autoPlay
            preventMovementUntilSwipeScrollTolerance={true}
            useKeyboardArrows
            transitionTime={1000}
            className={styles.imageSlider}
          >
            {data?.map((attraction) => {
              return (
                <div
                  className={styles.sliderContentContainer}
                  key={attraction.title[ctx.preferedLang]}
                >
                  <img
                    src={attraction.image}
                    alt={attraction.title['en']}
                    className={styles.attractionsImage}
                  />
                  <div className={styles.attractionsStoryContainer}>
                    <span className={styles.articleTitle}>
                      {attraction.title[ctx.preferedLang]}
                    </span>
                    <span className={styles.articleText}>
                      {attraction.description[ctx.preferedLang]}
                    </span>
                    <Link
                      to={`/${ctx.preferedLang}${attraction.pageUrl}`}
                      className={styles.moreInformation}
                    >
                      {keyWordsLocalization.exploreMore[ctx.preferedLang]}
                    </Link>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
    </LangContext.Consumer>
  );
};

export default HomePageAttractionsList;
