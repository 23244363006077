import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import LangContext from '../../Store/LangContext';
import styles from '../../Styles/Hotels.module.css';
import HotelCardSlider from './HotelCardSlider';
import { loadContent } from '../../Service/ApiService';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const Hotels = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('HotelsData', 'HotelsData');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  if (data?.length === 0) return <LoadingSpinner />;

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.hotelsCradsContainer}>
            {data?.map((hotel) => {
              return (
                <Card className={styles.hotelCard} key={hotel.id}>
                  <div className={styles.hotelsImagesContainer}>
                    <HotelCardSlider>
                      {hotel.images.map((hotelImage, index) => {
                        return (
                          <img
                            src={hotelImage}
                            alt={`${hotel.name['en']}_${index}`}
                            key={hotelImage}
                          />
                        );
                      })}
                    </HotelCardSlider>
                  </div>
                  <Card.Body className={styles.hotelsCardBody}>
                    <div className={styles.hotelDetailsContainer}>
                      <div className={styles.hotelMainRequisitesContainer}>
                        <div className={styles.hotelTitleContainer}>
                          <span className={styles.hotelTitle}>
                            {hotel.name[ctx.preferedLang]}
                          </span>
                        </div>
                      </div>
                      <div className={styles.hotelInformationBlock}>
                        <span className={styles.hotelInformationTitle}>
                          {hotel.locationName[ctx.preferedLang]}
                        </span>
                        <span className={styles.hotelInformationText}>
                          {hotel.location[ctx.preferedLang]}
                        </span>
                      </div>
                      <div className={styles.hotelInformationBlock}>
                        <span className={styles.hotelInformationTitle}>
                          {hotel.phoneName[ctx.preferedLang]}
                        </span>
                        <a
                          className={styles.hotelInformationText}
                          href={`tel:${hotel.phone}`}
                        >
                          {hotel.phone}
                        </a>
                      </div>
                      <div className={styles.hotelInformationBlock}>
                        <span className={styles.hotelInformationTitle}>
                          {hotel.typeName[ctx.preferedLang]}
                        </span>
                        <span className={styles.hotelInformationText}>
                          {hotel.type[ctx.preferedLang]}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default Hotels;
