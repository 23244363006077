import React, { useEffect } from 'react';
import keyWordsLocalization from '../Localization/Key_Words';
import LangContext from '../Store/LangContext';
import ProgressiveImage from '../Components/ProgressiveImage';
import ActivitiesList from '../Components/Things_To_Do_Components/ActivitiesList';
import Cookies from 'js-cookie';
import styles from '../Styles/ThingsToDo.module.css';

const ThingsToDo = () => {
  const lng = Cookies.get('preferedLanguage');
  useEffect(() => {
    document.title =
      lng === 'hy'
        ? keyWordsLocalization.thingsToDo.hy.toUpperCase()
        : lng === 'ru'
        ? keyWordsLocalization.thingsToDo.ru.toUpperCase()
        : keyWordsLocalization.thingsToDo.en.toUpperCase();
  }, [lng]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.container}>
            <div className={styles.backgroundImage}>
              <ProgressiveImage
                src='../../Assets/Images/Things_To_Do/Things_To_Do_Background_High.webp'
                placeholder='../../Assets/Images/Things_To_Do/Things_To_Do_Background_Low.webp'
                className={styles.coverImage}
              />
              <div className={styles.titleContainer}>
                <h1>
                  {keyWordsLocalization.thingsToDo[
                    ctx.preferedLang
                  ].toUpperCase()}
                </h1>
              </div>
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.blockContainer}>
                <div className={styles.contentTitleContainer}>
                  <span>
                    {keyWordsLocalization.activityTitle[ctx.preferedLang]}
                  </span>
                </div>
                <ActivitiesList />
              </div>
            </div>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default ThingsToDo;
