import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";

import styles from "../../Styles/ImageSlider.module.css";

const ImageSlider = (props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const next = () => {
    setCurrentImageIndex(currentImageIndex + 1);
  };
  const prev = () => {
    setCurrentImageIndex(currentImageIndex - 1);
  };
  return (
    <div className={styles.carouselContainer}>
      <button className={styles.sliderPrevButton} onClick={prev}>
        <img src="../../Assets/Icons/Slider/PrevIcon.svg" alt="previous slide icon" />
      </button>
      <Carousel
        selectedItem={currentImageIndex}
        onChange={setCurrentImageIndex}
        showStatus={props.showStatus}
        showThumbs={props.showThumbs}
        showIndicators={props.showIndicators}
        showArrows={props.showArrows}
        infiniteLoop={props.infiniteLoop}
        transitionTime={200}
        useKeyboardArrows
        className={styles.carouselPosition}
      >
        {props.children}
      </Carousel>
      <button className={styles.sliderNextButton} onClick={() => next()}>
        <img src="../../Assets/Icons/Slider/NextIcon.svg" alt="next slide icon" />
      </button>
    </div>
  );
};

export default ImageSlider;
