import React from "react";
import { Carousel } from "react-responsive-carousel";

const PageSlider = (props) => {
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      showIndicators={true}
      infiniteLoop
      autoPlay
      useKeyboardArrows
      transitionTime={1000}
    >
      {props.children}
    </Carousel>
  );
};

export default PageSlider;
