import React from 'react';
import Hotels from '../Components/Hotels_Components/Hotels';
import keyWordsLocalization from '../Localization/Key_Words';
import LangContext from '../Store/LangContext';
import PageContentDescription from '../Components/PageContentDescription';
import ProgressiveImage from '../Components/ProgressiveImage';
import styles from '../Styles/PlacesToStay.module.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import MetaTitle from '../Components/Meta/MetaTitle';

const PlacesToStay = () => {
  return (
    <>
      <LangContext.Consumer>
        {(ctx) => {
          return (
            <>
              <MetaTitle
                title={
                  keyWordsLocalization.placesToStaryMetaTitle[ctx.preferedLang]
                }
                name='description'
                content={
                  keyWordsLocalization.placesToStaryMetaTitle[ctx.preferedLang]
                }
                preload={true}
                imageUrl={
                  '../../Assets/Images/Container_Background/Places_To_Stay.webp'
                }
              />
              <section className={styles.backgroundImage}>
                <ProgressiveImage
                  src='../../Assets/Images/Container_Background/Places_To_Stay.webp'
                  placeholder='../../Assets/Images/Container_Background/Places_To_Stay_Low.webp'
                  className={styles.coverImage}
                />
                <div className={styles.placeTextContainer}>
                  <div className={styles.placeTextColumn}>
                    <img
                      src='../Assets/Icons/Hotels/To_Stay.svg'
                      alt='Where to stay'
                      className={styles.placesToStayIcon}
                    />
                    <div className={styles.placesTextContainer}>
                      <h1 className={styles.placesPrimaryText}>
                        {
                          keyWordsLocalization.placesToStaryTextHeader[
                            ctx.preferedLang
                          ]
                        }
                      </h1>
                      <p className={styles.placesRegularText}>
                        {
                          keyWordsLocalization.placesToStaryText[
                            ctx.preferedLang
                          ]
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <PageContentDescription id='places-to-stay' />
              <div className={styles.placesBody}>
                <Hotels />
              </div>
            </>
          );
        }}
      </LangContext.Consumer>
    </>
  );
};

export default PlacesToStay;
