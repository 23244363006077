import React from 'react';
import { Link } from 'react-router-dom';
import LangContext from '../../Store/LangContext';
import { nanoid } from 'nanoid';
import ProgressiveImage from '../ProgressiveImage';
import styles from '../../Styles/Home.module.css';

const HomePageBackgroundImage = (props) => {
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.backgroundImage}>
            <ProgressiveImage
              src='Assets/Images/Container_Background/Home_Background_High_Quality.webp'
              placeholder={
                'Assets/Images/Container_Background/Home_Background_High_Low_Quality.webp'
              }
              alt='Dsegh main background'
            />
            <Link
              to={`/${ctx.preferedLang}/things-to-do`}
              className={styles.banner}
            >
              <div className={styles.bannerBlock}>
                {props.data?.bannerTitle?.map((item) => {
                  if (item.type === 'mainTitle') {
                    return (
                      <h1 className={styles.bannerTitle} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </h1>
                    );
                  } else if (item.type === 'secondaryTitle') {
                    return (
                      <h2 className={styles.bannerTitle} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </h2>
                    );
                  } else {
                    return (
                      <span className={styles.bannerTitle} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </span>
                    );
                  }
                })}
                {props.data.banner?.map((item) => {
                  if (item.type === 'mainTitle') {
                    return (
                      <h1 className={styles.bannerText} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </h1>
                    );
                  } else if (item.type === 'secondaryTitle') {
                    return (
                      <h2 className={styles.bannerText} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </h2>
                    );
                  } else {
                    return (
                      <span className={styles.bannerText} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </span>
                    );
                  }
                })}
              </div>
            </Link>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default HomePageBackgroundImage;
