import React from "react";
import ProgressiveImage from "../ProgressiveImage";
import styles from "./PageDetails.module.css";

const PageBackground = (props) => {
  return (
    <div className={styles.backgroundImage}>
      <ProgressiveImage
        src={props.src}
        placeholder={props.placeholder}
        className={styles.coverImage}
      />
      <h1>{props.title}</h1>
    </div>
  );
};

export default PageBackground;
