import React from 'react';
import { Link } from 'react-router-dom';
import LangContext from '../Store/LangContext';
import footerLocalization from '../Localization/Footer_Localization';
import navigationBarLocalization from '../Localization/Navigation_Bar_Localization';
import FooterComponent from './Footer_Components/FooterComponent';
import styles from '../Styles/Footer.module.css';

const Footer = () => {
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.footerContainer}>
            <div className={styles.footerUpperBlock}>
              <div className={styles.footerInformativeBlock}>
                <div className={styles.imageContainer}>
                  <img
                    src='../../Assets/Images/Logotypes/Dsegh_Logo_Arm.png'
                    className={styles.logo}
                    alt='dsegh.am logotype'
                  />
                </div>
                <div className={styles.textBlock}>
                  <span className={styles.textTitle}>
                    {footerLocalization.footerTextTitle[
                      ctx.preferedLang
                    ].toUpperCase()}
                  </span>
                  <br />
                  <span className={styles.regularText}>
                    {footerLocalization.footerText[ctx.preferedLang]}
                  </span>
                </div>
              </div>
              <div className={styles.footerBlock}>
                <span className={styles.textTitle}>
                  {navigationBarLocalization.thingsToDo[
                    ctx.preferedLang
                  ].toUpperCase()}
                </span>
                <ul className={styles.footerBlocksLists}>
                  <li>
                    <Link to={`/${ctx.preferedLang}/cycling`}>
                      <FooterComponent
                        sectionTitle={
                          footerLocalization.cycling[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/hiking`}>
                      <FooterComponent
                        sectionTitle={
                          footerLocalization.hiking[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/camping`}>
                      <FooterComponent
                        sectionTitle={
                          footerLocalization.camping[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/rafting`}>
                      <FooterComponent
                        sectionTitle={
                          footerLocalization.waterActivities[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/horseback-riding`}>
                      <FooterComponent
                        sectionTitle={
                          footerLocalization.horsebackRiding[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/discovering-nature`}>
                      <FooterComponent
                        sectionTitle={
                          footerLocalization.discoveringNature[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={styles.footerBlock}>
                <span className={styles.textTitle}>
                  {navigationBarLocalization.getToKnowTheCountry[
                    ctx.preferedLang
                  ].toUpperCase()}
                </span>
                <ul className={styles.footerBlocksLists}>
                  <li>
                    <Link to={`/${ctx.preferedLang}/history-and-culture`}>
                      <FooterComponent
                        sectionTitle={
                          navigationBarLocalization.historyAndCulture[
                            ctx.preferedLang
                          ]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/sightseengs`}>
                      <FooterComponent
                        sectionTitle={
                          navigationBarLocalization.sightseeing[
                            ctx.preferedLang
                          ]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/events`}>
                      <FooterComponent
                        sectionTitle={
                          navigationBarLocalization.events[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/museums`}>
                      <FooterComponent
                        sectionTitle={
                          navigationBarLocalization.museums[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ctx.preferedLang}/gallery`}>
                      <FooterComponent
                        sectionTitle={
                          navigationBarLocalization.gallery[ctx.preferedLang]
                        }
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.copyRightContainer}>
              <span>
                © {new Date().getFullYear()}{' '}
                <Link to={`/${ctx.preferedLang}`}>Dsegh.am. </Link>
                {footerLocalization.allRightReserved[ctx.preferedLang]}
              </span>
              <a
                href='https://www.dynamic.am/'
                target='_blank'
                rel='noreferrer'
                className={styles.developerLogoContainer}
              >
                <span>
                  {footerLocalization.madeBy[ctx.preferedLang].toUpperCase()}
                </span>
                <img
                  src='../../Assets/Images/Logotypes/Dynamic_White.png'
                  className={styles.developerLogo}
                  alt='web page developer logotype'
                />
              </a>
            </div>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default Footer;
