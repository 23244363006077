import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const LoadingSpinner = () => {
  const spinner = {
    backgroundColor: 'inherit',
    height: '60px',
    width: '60px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20% auto',
    color: '#102d33',
    border: '10px solid',
    borderRight: '10px solid #0000',
  };
  return <Spinner animation='border' role='status' style={spinner} />;
};

export default LoadingSpinner;
