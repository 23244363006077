import React from "react";
import EventsList from "../../Components/Things_To_Do_Components/EventsList";
import LangContext from "../../Store/LangContext";
import keyWordsLocalization from "../../Localization/Key_Words";
import styles from "../../Styles/To_Do_Pages.module.css";
import ProgressiveImage from "../../Components/ProgressiveImage";
import PageContentDescription from "../../Components/PageContentDescription";
import MetaTitle from "../../Components/Meta/MetaTitle";

const Events = () => {
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <>
            <MetaTitle
              title={keyWordsLocalization.eventsMetaTitle[ctx.preferedLang]}
              name="description"
              content={keyWordsLocalization.eventsMetaTitle[ctx.preferedLang]}
            />
            <div className={styles.container}>
              <div className={styles.backgroundImage}>
                <ProgressiveImage
                  src="../../Assets/Images/Pages_Backgrounds/Events_Background_High.webp"
                  placeholder="../../Assets/Images/Pages_Backgrounds/Events_Background_Low.webp"
                  className={styles.coverImage}
                />
                <div className={styles.titleContainer}>
                  <h1>
                    {keyWordsLocalization.events[
                      ctx.preferedLang
                    ].toUpperCase()}
                  </h1>
                </div>
              </div>
              <PageContentDescription id="events" />
              <div className={styles.contentContainer}>
                <div className={styles.blockContainer}>
                  <div className={styles.blockContainer}>
                    <EventsList />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </LangContext.Consumer>
  );
};

export default Events;
