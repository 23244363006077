import React from "react";
import styles from "./PageDetails.module.css";

const Suggestions = (props) => {
  return (
    <div className={styles.suggestionsContainer}>
      <span className={styles.sectionTitle}>{props.suggestionTitle}</span>
      {props.children}
    </div>
  );
};

export default Suggestions;
