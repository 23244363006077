import React, { useContext } from 'react';
import { Languages } from '../../Translations/Languages';
import LangContext from '../../Store/LangContext';
import Cookies from 'js-cookie';
import styles from './Dropdown.module.css';

const ChooseLanguage = (props) => {
  const { preferedLang, setLanguage } = useContext(LangContext);

  const handleClick = (lang) => {
    if (lang !== preferedLang && ['en', 'hy', 'ru'].includes(lang)) {
      Cookies.set('preferedLanguage', lang);
      setLanguage(lang);
    }
    const newUrl = window.location.href.replace(/\/en|\/hy|\/ru/, `/${lang}`);
    window.location.replace(newUrl);
    props.setLanguagesVisiable(false);
    props.setOpenedSidebar(false);
  };

  return (
    <div
      className={
        props.languagesVisiable
          ? styles.dropdownLocalization
          : styles.dropdownItemHidden
      }
    >
      <div
        className={`${styles.dropdownContainer} ${styles.localizationDropdownContainer}`}
      >
        {Languages.map((item) => {
          return (
            <div
              className={styles.languageFlag}
              key={item.title}
              onClick={() => handleClick(item.title)}
            >
              <img
                src={item.image}
                alt='Language choose icon'
                className={styles.localizationItem}
                onClick={() => props.onPress}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChooseLanguage;
