import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ThingsToDoDetails.module.css';
import LangContext from '../../Store/LangContext';

const PlacesToVisitDetails = (props) => {
  const { id } = useParams();
  const placesData = props.data?.find((places) => places.id === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const placesToVisitDetailsBody = (lang) => (
    <>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={placesData.icons[0]}
            className={styles.generalInformationIcon}
            alt='Type'
          />
          <span className={styles.generalInformationName}>
            {placesData.informationTitles[0]}
          </span>
        </div>
        <span className={styles.generalInformation}>{placesData.type}</span>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={placesData.icons[1]}
            className={styles.generalInformationIcon}
            alt='Location'
          />
          <span className={styles.generalInformationName}>
            {placesData.informationTitles[1]}
          </span>
        </div>
        <span className={styles.generalInformation}>{placesData.location}</span>
      </div>
      <div className={styles.singleBlockContainer}>
        <div className={styles.nameBlock}>
          <img
            src={placesData.icons[2]}
            className={styles.generalInformationIcon}
            alt='Distance'
          />
          <span className={styles.generalInformationName}>
            {placesData.informationTitles[2]}
          </span>
        </div>
        <span className={styles.generalInformation}>{placesData.distance}</span>
      </div>
    </>
  );

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return <>{placesToVisitDetailsBody(ctx.preferedLang)}</>;
      }}
    </LangContext.Consumer>
  );
};

export default PlacesToVisitDetails;
