import React, { useEffect, useState } from 'react';
import LangContext from '../../Store/LangContext';
import navigationBarLocalization from '../../Localization/Navigation_Bar_Localization';
import styles from '../../Styles/HistoryAndCulture.module.css';
import ProgressiveImage from '../../Components/ProgressiveImage';
import keyWordsLocalization from '../../Localization/Key_Words';
import { nanoid } from 'nanoid';
import MetaTitle from '../../Components/Meta/MetaTitle';
import { loadContent } from '../../Service/ApiService';

const HistoryAndCulture = () => {
  const [pageData, setPageData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('HistoryData', 'HistoryData');
      if (fetchData) setPageData(fetchedData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const historyAndCultureBody = (lang) =>
    pageData?.map((data) => {
      return (
        <div
          className={
            pageData?.indexOf(data) % 2 === 0
              ? styles.pageBlocks
              : `${styles.pageBlocks} ${styles.pageBlocksReverse}`
          }
          key={data.key}
        >
          <div className={styles.blockImageContainer}>
            <ProgressiveImage src={data.image} placeholder={data.image} />
          </div>
          <div className={styles.descriptionContainer}>
            <h1 className={styles.articleTitle}>{data.title[lang]}</h1>
            <div className={styles.textLeft}>
              {data.mainBody.map((item) => {
                if (item.type === 'mainHeader') {
                  return (
                    <h2 className={styles.titleText} key={nanoid()}>
                      {item.text[lang]}&nbsp;
                    </h2>
                  );
                } else if (item.type === 'secondaryHeader') {
                  return (
                    <h3 className={styles.titleText} key={nanoid()}>
                      {item.text[lang]}&nbsp;
                    </h3>
                  );
                } else if (item.type === 'anchorText') {
                  return (
                    <a
                      className={styles.anchorText}
                      href={item.link[lang]}
                      key={nanoid()}
                    >
                      {item.text[lang]}&nbsp;
                    </a>
                  );
                } else {
                  return (
                    <span className={styles.articleText} key={nanoid()}>
                      {item.text[lang]} &nbsp;
                    </span>
                  );
                }
              })}
            </div>
          </div>
        </div>
      );
    });
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <>
            <MetaTitle
              title={
                keyWordsLocalization.historyAndCultureMetaTitle[
                  ctx.preferedLang
                ]
              }
              name='description'
              content={
                keyWordsLocalization.historyAndCultureMetaTitle[
                  ctx.preferedLang
                ]
              }
            />
            <div className={styles.container}>
              <div className={styles.backgroundImage}>
                <ProgressiveImage
                  src='../../Assets/Images/History/History_And_Culture_Background_High.webp'
                  placeholder='../../Assets/Images/History/History_And_Culture_Background_Low.webp'
                  className={styles.coverImage}
                />
                <div className={styles.titleContainer}>
                  <h1>
                    {navigationBarLocalization.historyAndCulture[
                      ctx.preferedLang
                    ].toUpperCase()}
                  </h1>
                </div>
              </div>
              <div className={styles.historyContainer}>
                {historyAndCultureBody(ctx.preferedLang)}
              </div>
            </div>
          </>
        );
      }}
    </LangContext.Consumer>
  );
};

export default HistoryAndCulture;
