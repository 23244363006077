import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import keyWordsLocalization from '../../Localization/Key_Words';
import Suggestions from '../../Components/Things_To_Do_Pages_Components/Suggestions';
import PageDescription from '../../Components/Things_To_Do_Pages_Components/PageDescription';
import PageSlider from '../../Components/Things_To_Do_Pages_Components/PageSlider';
import SharePageInSocialMedia from '../../Components/Things_To_Do_Pages_Components/SharePageInSocialMedia';
import PageLocationOnMap from '../../Components/Things_To_Do_Pages_Components/PageLocationOnMap';
import PageBackground from '../../Components/Things_To_Do_Pages_Components/PageBackground';
import { nanoid } from 'nanoid';
import styles from './ThingsToDoDetails.module.css';
import LangContext from '../../Store/LangContext';
import EventsDetails from './EventsDetails';
import PlacesToVisitDetails from './PlacesToVisitDetails';
import HouseMuseum from './HouseMuseum';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import MetaTitle from '../../Components/Meta/MetaTitle';
import { loadContent } from '../../Service/ApiService';

const ToDoPages = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('ThingsToDoData', 'ThingsToDoData');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  const toDoData = data?.find((item) => item.id === id);

  let prefLang = Cookies.get('preferedLanguage');
  const newUrl = window.location.pathname.replace(/\/en|\/hy|\/ru/, '');
  useEffect(() => {
    if (!props.routs?.includes(newUrl.toString())) {
      navigate(`/${prefLang}/page-not-found`, { replace: true });
      return;
    }
    window.scrollTo(0, 0);
  });
  if (!props.routs?.includes(newUrl.toString())) {
    navigate(`/${prefLang}/page-not-found`, { replace: true });
    return;
  }
  const toDoPagesBody = (lang) => (
    <div className={styles.container}>
      <MetaTitle
        title={toDoData?.metaTitle[lang]}
        name='description'
        content={toDoData?.metaTitle[lang]}
      />
      <PageBackground
        src={toDoData?.backgroundImageHigh}
        placeholder={toDoData?.backgroundImageLow}
        title={toDoData?.title[lang].toUpperCase()}
      />
      <div className={styles.sliderContainer}>
        <PageSlider>
          {toDoData?.images?.map((image) => {
            return (
              <img
                src={image.image}
                style={{ background: `url(${image.image})` }}
                className={styles.sliderImage}
                alt={image.key}
                key={image.key}
              />
            );
          })}
        </PageSlider>
      </div>
      <div className={styles.blockContainer}>
        <PageLocationOnMap />
      </div>
      <div className={styles.blockContainer}>
        <PageDescription
          descriptionTitle={toDoData?.descriptionTitle[lang]}
          description={toDoData?.description.map((item) => {
            if (item.type === 'mainHeader') {
              return (
                <h2 className={styles.titleText} key={nanoid()}>
                  {item.text[lang]}
                </h2>
              );
            } else if (item.type === 'secondaryHeader') {
              return (
                <h3 className={styles.titleText} key={nanoid()}>
                  {item.text[lang]}
                </h3>
              );
            } else if (item.type === 'anchorText') {
              return (
                <a
                  className={styles.anchorText}
                  href={item.link[lang]}
                  key={nanoid()}
                >
                  {item.text[lang]}
                </a>
              );
            } else {
              return (
                <span className={styles.titleText} key={nanoid()}>
                  {item.text[lang]}
                </span>
              );
            }
          })}
          informationTitle={toDoData?.informationTitle[lang]}
          information={
            toDoData?.kind === 'events' ? (
              <EventsDetails data={data} />
            ) : toDoData?.kind === 'museums' ? (
              <HouseMuseum data={data} />
            ) : toDoData?.kind === 'attractions' ? (
              <PlacesToVisitDetails data={data} />
            ) : (
              toDoData?.information.map((item) => {
                if (item.type === 'mainHeader') {
                  return (
                    <h2 className={styles.titleText} key={nanoid()}>
                      {item.text[lang]}
                    </h2>
                  );
                } else if (item.type === 'secondaryHeader') {
                  return (
                    <h3 className={styles.titleText} key={nanoid()}>
                      {item.text[lang]}
                    </h3>
                  );
                } else if (item.type === 'anchorText') {
                  return (
                    <a
                      className={styles.anchorText}
                      href={item.link[lang]}
                      key={nanoid()}
                    >
                      {item.text[lang]}
                    </a>
                  );
                } else {
                  return (
                    <span className={styles.titleText} key={nanoid()}>
                      {item.text[lang]}
                    </span>
                  );
                }
              })
            )
          }
        />
      </div>
      <Suggestions suggestionTitle={keyWordsLocalization.suggestions[lang]}>
        <div className={styles.suggestionsItemContainer}>
          <div className={styles.suggestionsBlock}>
            {toDoData?.activitySuggestions?.map((suggestion) => {
              return (
                <Link
                  to={'/' + [lang] + suggestion.pageUrl[lang]}
                  className={styles.suggestionsItem}
                  key={suggestion.key}
                >
                  <img
                    src={suggestion.icon}
                    alt={suggestion.title['en']}
                    className={styles.suggestionsIcon}
                  />
                  <span className={styles.suggestionsName}>
                    {suggestion.title[lang]}
                  </span>
                </Link>
              );
            })}
          </div>
          <div className={styles.suggestionsBlock}>
            {toDoData?.placesToVisitSuggestions?.map((suggestion) => {
              return (
                <Link
                  to={'/' + [lang] + suggestion.pageUrl[lang]}
                  className={styles.suggestionsItem}
                  key={suggestion.key}
                >
                  <img
                    src={suggestion.icon}
                    alt={suggestion.title['en']}
                    className={styles.suggestionsIcon}
                  />
                  <span className={styles.suggestionsName}>
                    {suggestion.title[lang]}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      </Suggestions>
      <div className={styles.shareContainer}>
        <SharePageInSocialMedia pageUrl={window.location.href} />
      </div>
    </div>
  );
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return toDoPagesBody(ctx.preferedLang);
      }}
    </LangContext.Consumer>
  );
};

export default ToDoPages;
