const keyWordsLocalization = {
  signUp: {
    en: "Sign up",
    hy: "Գրանցվել",
    ru: "Регистрация",
  },
  signIn: {
    en: "Sign in",
    hy: "Մուտք",
    ru: "Войти",
  },
  help: {
    en: "Help",
    hy: "Օգնություն",
    ru: "Помощ",
  },
  exploreMore: {
    en: "Explore More",
    hy: "Ավելին",
    ru: "Подробнее",
  },
  thingsToDo: {
    en: "What to do",
    hy: "ինչով զբաղվել",
    ru: "чем заняться",
  },
  placesToStary: {
    en: "Where to stay",
    hy: "ամրագրել",
    ru: "где переночевать",
  },
  getToKnowTheCountry: {
    en: "discover Dsegh",
    hy: "բացահայտել Դսեղը",
    ru: "раскрыть Дсех",
  },
  profile: {
    en: "profile",
    hy: "անձնական էջ",
    ru: "профиль",
  },
  historyAndCulture: {
    en: "History and culture",
    hy: "պատմություն և մշակույթ",
    ru: "история и культура",
  },
  sightseeing: {
    en: "sightseeings",
    hy: "տեսարժան վայրեր",
    ru: "Достопримечательности",
  },
  nature: {
    en: "nature",
    hy: "բնություն",
    ru: "природа",
  },
  gallery: {
    en: "gallery",
    hy: "տեսադարան",
    ru: "галлерея",
  },
  footerTextTitle: {
    en: "Welcome to Dsegh",
    hy: "Բարի գալուստ  Դսեղ",
    ru: "Добро пожаловать в Дсех",
  },
  footerText: {
    en: "It is a place in Armenia that won’t leave you indifferent, where you will listen to all the sounds of nature and won’t get enough of the views, fragrances, and feelings.",
    hy: "Սա մի վայր է Հայաստանում, որը Ձեզ չի թողնի անտարբեր, որտեղ Դուք կլսեք բնության բոլոր ձայները և չեք կարողանա հագենալ տեսարանից, բույրերից և զգացումներից։",
    ru: "Это место в Армении, которое не оставит вас равнодушным, где вы будете слушать все звуки природы и не сможете насытиться от вида, ароматов и чувств.",
  },
  cycling: {
    en: "Cycling",
    hy: "Հեծանվավարություն",
    ru: "Езда на велосипеде",
  },
  hiking: {
    en: "Hiking",
    hy: "Քայլարշավ",
    ru: "Пеший поход",
  },
  camping: {
    en: "Camping",
    hy: "Քեմփինգ",
    ru: "Кемпинг",
  },
  waterActivities: {
    en: "Water activities",
    hy: "Ջրային զբաղմունք",
    ru: "Водные активности",
  },
  horsebackRiding: {
    en: "Horseback riding",
    hy: "Ձիարշավ",
    ru: "Конный поход",
  },
  discoveringNature: {
    en: "Discovering nature",
    hy: "Բացահայտել բնությունը",
    ru: "Знакомство с природой",
  },
  findUsInSocialNetwork: {
    en: "Follow us in social network",
    hy: "Հետևեք մեզ սոցիալական ցանցերում",
    ru: "Следите за нами в соцсетях",
  },
  madeBy: {
    en: "Made by",
    hy: "Պատրաստված է",
    ru: "Разработано",
  },
  allRightReserved: {
    en: "All right reserved",
    hy: "Բոլոր իրավունքները պաշտպանված են",
    ru: "Все права защищены",
  },
  adventureTitle: {
    en: "Start planing your adventure",
    hy: "Սկսեք ծրագրել Ձեր արկածները",
    ru: "Начните планировать ваше приключение",
  },
  adventureText: {
    en: "Dsegh is a wonderful place located in Lori Province of Armenia, where you can always find something new for yourself. Discover Dsegh newly with us!",
    hy: "Դսեղը չնաշխարհիկ վայր է՝ տեղակայված Հայաստանի Հանրապետության Լոռու մարզում, որը Դուք միշտ կարող եք նորովի բացահայտել ինքներդ Ձեզ համար։ Բացահայտեք Դսեղը նորովի մեր հետ։",
    ru: "Дсех - замечательное место, расположенное в провинции Лори в Армении, где всегда можно найти что-то новое для себя. Откройте Дсех новым образом с нами для себя! ",
  },
  events: {
    en: "Events",
    hy: "Միջոցառումներ",
    ru: "Меропреятия",
  },
  sightseeings: {
    en: "Sightseeings",
    hy: "Տեսարժան վայրեր",
    ru: "Достопримеча тельности",
  },
  museums: {
    en: "Museums",
    hy: "Թանգարաններ",
    ru: "Музеи",
  },
  activityTitle: {
    en: "Start planing your activity",
    hy: "Սկսեք ծրագրել Ձեր ժամանցը",
    ru: "Начните планировать вашу активность",
  },
  activityText: {
    en: "Select a category below to uncover the endless activities Dsegh has to offer. We are pretty sure, you’ll enjoy the time spent here during your tour in Lori.",
    hy: "Ներքոնշյալ կատեգորիաներից ընտրեք ձեր նախընտրածը բացահայտելու համար անսահման ժամանցների տարբերակները, որոնք կաող եք գտնել Դսեղում։ Եվ եթե ծրագրել եք տուր դեպի Դսեղ, ապա մենք գրեթե համոզված ենք, որ դուք կվայելեք այստեղ անցկացրած յուրաքանչյուր վայրկյան։",
    ru: "Выберите категорию ниже, чтобы раскрыть бесконечные активности, которые может предложить Дсех. И если планируете тур в Лори, мы почти уверены, вы насладитесь временем, проведенным здесь в течении.",
  },
  historyTitle: {
    en: "Get acquainted with the history of the region and enjoy the Miracles of Lori",
    hy: "Ծանոթացեք տարածաշրջանի պատմությանը և վայելեք Լոռվա հրաշալիքները",
    ru: "Понакомтесь с историей региона и наслаждайтесь с чудесами Лори",
  },
  historyText: {
    en: "Discover the centuries-old history of Dsegh in a new way",
    hy: "Բացահայտեք Դսեղի տեսարժան վայրերը և դարավոր պատմությունը նորովի",
    ru: "Раскройте многовековую историю Дсеха новым образом",
  },
  stGregoryMonastry: {
    en: "The Monastery of Bardzrakash St. Gregory",
    hy: "Բարձրաքաշ Սուրբ Գրիգոր վանք",
    ru: "Монастырь Бардзракаш Сурб Григор",
  },
  fortyChildrenMonastry: {
    en: "Monastery of Karasnits Mankants (Forty Children Monastery)",
    hy: "Քառասնից Մանկանց վանք",
    ru: "Монастырь Карасниц Манканц («Монастырь сорока младенцев»)",
  },
  tsoverLake: {
    en: "Tsover lake",
    hy: "Ծովեր լիճ",
    ru: "Цовер озеро",
  },
  sirunKhach: {
    en: "Sirun Khach cross stone",
    hy: "Սիրուն խաչ խաչքար",
    ru: "Сирун хач каменный крест",
  },
  placeToStayTitle: {
    en: 'Where to stay',
    hy: 'ամրագրել',
    ru: 'Где переночевать'
  },
  placeToStayText: {
    en: "You can always find the perfect place to lay your head for the night in Dsegh.",
    hy: "Դուք միշտ կարող եք գտնել Դսեղում իդեալական վայր գիշերելու համար։",
    ru: "Вы всегда можете найти идеальное место, чтобы переночевать в Дехе.",
  },
  placeToStayDescription: {
    en: "With an impressive variety and price range to fit every budget, you can see everything this natural world has to offer during the day…and sleep warm and cozy the whole night through during your tour to Dsegh.",
    hy: "Տպավորիչ բազմազանությամբ և ցանկացած բյուջեին համապատասխան գնային միջակայքով Դուք կարող եք օրվա ընթացքում տեսնել այն ամենը, ինչ առաջարկում է այս բնական աշխարհը․․․ և քնել տաքուկ և հարմարավետ ամբողջ գիշերվա ընթացքում",
    ru: "С впечатляющим разнообразием и диапазоном цен, в соответствии с каждым бюджетом, вы можете увидеть все, что этот природный мир может предложить в течение дня во время тура в Дсех... и спать тепло и уютно всю ночь напролет.",
  },
  placesToVisit: {
    en: "Where to visit",
    hy: "Տեսարժան վայրեր",
    ru: "Достопримечательности",
  },
  location: {
    en: "Dsegh, Lori Province, Armenia",
    hy: "Դսեղ, Լոռու մարզ, Հայաստան",
    ru: "Дсех, Лорийская облость, Армения",
  },
  shareThisPage: {
    en: 'Share this page',
    hy: 'Կիսվել սոցցանցերում',
    ru: 'Поделиться в соцсетях'
  },
  moreAboutEvents: {
    en: 'Start meeting the people and culture of the region',
    hy: 'Սկաեք ծանոթությունը տարածաշրջանի մարդկանց և մշակույթի հետ',
    ru: 'Начните знакомство с людьми и культурой региона'
  },
  moreAboutSightseengs: {
    en: 'Discover the nature and the history of the region in a new way!',
    hy: 'Բացահայտեք տարածաշրջանի պատմությունն ու բնությունը նորովի։',
    ru: 'Раскройте природу и историю региона по-новому!'
  },
  moreAboutMuseums: {
    en: 'Start planning exciting fun for the day',
    hy: 'Սկսեք պլանավորել Ձեր օրվա հետաքրքիր ժամանցը',
    ru: 'Начните планировать захватывающие развлечения на день'
  },
  bannerTitle: {
    en: 'The small, green fairy tail in the heart of the mountains․․․',
    hy: 'Հեքիաթ լեռների սրտում․․․',
    ru: 'Маленькая зеленая сказка в сердце гор․․․'
  },
  bannerText: {
    en: 'Dsegh is one of the most beautiful regions in Lori province, and not only. Explore and plan your trip',
    hy: 'Դսեղը Լոռու մարզի ամենագեղեցիկ շրջաններից է։ Ուսումնասիրեք և ծրագրեք ձեր ճանապարհորդությունը։',
    ru: 'Дсех - один из самых красивых регионов в Лорийской области и не только. Изучите и планируйте свою поездку.'
  },
  suggestions: {
    en: 'You may also like:',
    hy: 'Ձեզ, հնարավոր է, դուր գա նաև՝',
    ru: 'Вам также может понравиться:'
  },
  fromActivities: {
    en: 'From Activities',
    hy: 'Զբաղմունքներից',
    ru: 'Из Активностей'
  },
  fromSightseengs: {
    en: 'From Sightseeings',
    hy: 'Տեսարժան Վայրերից',
    ru: 'Из достопримечательностей'
  },
  galleryTitle: {
    en: 'Here you can find the whole beauty of Dsegh...',
    hy: 'Այստեղ Դուք կարող եք գտնել Դսեղն իր ամբեղջ գեղեցկությամբ...',
    ru: 'Здесь Вы сможете найти Дсех во всей его красе...'
  },
  placesToStaryTextHeader: {
    en: 'Stay in wonderland for a while.',
    hy: 'Մնացեք որոշ ժամանակ Հրաշքների աշխարհում։',
    ru: 'Останься в чудесной стране на некоторое время.'
  },
  placesToStaryText: {
    en: 'You can always find the perfect place to lay your head for the night in Dsegh.',
    hy: 'Դսեղում Դուք միշտ կարող եք գտնել կատարյալ վայր՝ գիշերվա ընթացքում Ձեր գլուխը վայր դնելու համար:',
    ru: 'Вы всегда можете найти идеальное место, чтобы положить голову на ночь в Дсехе.'
  },
  appTitle: {
    en: 'Tour to Dsegh, Lori | Extreme Tourism | Dsegh',
    hy: 'Դսեղի Տեսարժան Վայրերը | Էքատրեմալ Տուրիզմ | Դսեղ',
    ru: 'Тур в Дсех, Лори | Экстремальный Туризм | Дсех'
  },
  placesToStaryMetaTitle: {
    en: 'Overnight Tours in Dsegh | Where to Stay in Dsegh? | Dsegh',
    hy: 'Որտեղ Գիշերել Դսեղում | Հյուրանոցներ Դսեղում | Դսեղ',
    ru: 'Где Переночевать в Дсехе | Отели в Дсехе | Дсех'
  },
  eventsMetaTitle: {
    en: 'Events in Dsegh | Tumanyan’s Days in Dsegh | Dsegh',
    hy: 'Միջոցառումներ Դսեղում | Թումանյանական օրեր Դսեղում',
    ru: 'Мероприятия в Дсехе | Туманянские Дни в Дсехе | Дсех'
  },
  historyAndCultureMetaTitle: {
    en: 'History of Dsegh | About Dsegh Culture | Dsegh',
    hy: 'Դսեղի պատմությունը և Մշակույթը | Դսեղի Մասին | Դսեղ',
    ru: 'О Дсеге | История и Культура Дсега | Дсех'
  },
  museumsMetaTitle: {
    en: 'Dsegh House-Museum | House-Museum of Tumanyan | Dsegh',
    hy: 'Թանգարաններ Դսեղում | Թումանյանական օրեր Դսեղում | Դսեղ',
    ru: 'Музеи Дсеха | Дом-Музей Туманяна | Дсех'
  },
  sightseengsMetaTitle: {
    en: 'Sights of Dsegh | Where to go & See in Dsegh? | Dsegh',
    hy: 'Տեսարժան Վայրեր Դսեղում | Որտեղ Գնալ Դսեղում | Դսեղ',
    ru: 'Достопримечательности Дсеха | Что Посмотреть в Дсехе? | Дсех'
  }
};

export default keyWordsLocalization;
