import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import keyWordsLocalization from '../../Localization/Key_Words';
import LangContext from '../../Store/LangContext';
import styles from '../../Styles/Activities.module.css';
import { loadContent } from '../../Service/ApiService';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const HomePageActivitiesList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('Activities', 'ActivitiesData');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);
  const activitiesData = data?.slice(0, 4);

  if (data?.length === 0) return <LoadingSpinner />;

  const activitiesSection = (lang) =>
    activitiesData?.map((item) => {
      return (
        <div className={styles.activitiesContainer} key={item.id}>
          <img src={item.cardImage} alt={item.title['en']} />
          <div className={styles.activitiesInformation}>
            <div>
              <h2>{item.title[lang]}</h2>
            </div>
            <div className={styles.textContainer}>
              <p>{item.description[lang]}</p>
            </div>
            <Link to={`/${lang}/` + item.id} className={styles.readMoreButton}>
              {keyWordsLocalization.exploreMore[lang]}
            </Link>
          </div>
        </div>
      );
    });

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return <>{activitiesSection(ctx.preferedLang)}</>;
      }}
    </LangContext.Consumer>
  );
};

export default HomePageActivitiesList;
