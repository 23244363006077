import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import keyWordsLocalization from '../../Localization/Key_Words';
import LangContext from '../../Store/LangContext';
import styles from '../../Styles/ActivitiesList.module.css';
import ProgressiveImage from '../ProgressiveImage';
import { loadContent } from '../../Service/ApiService';
import LoadingSpinner from '../Spinner/LoadingSpinner';

const ActivitiesList = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('Activities', 'ActivitiesData');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (data?.length === 0) return <LoadingSpinner />;

  const activitiesListSection = (lang) =>
    data?.map((activity) => {
      return (
        <div className={styles.activitiesContainer} key={activity.id}>
          <ProgressiveImage
            src={activity.cardImage}
            placeholder={activity.cardImage}
          />
          <div className={styles.activitiesInformation}>
            <div>
              <h2>{activity.title[lang]}</h2>
            </div>
            <div className={styles.titleContainer}>
              <p>{activity.description[lang]}</p>
            </div>
            <Link
              to={`/${lang}/` + activity.id}
              className={styles.readMoreButton}
            >
              {keyWordsLocalization.exploreMore[lang]}
            </Link>
          </div>
        </div>
      );
    });
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.container}>
            {activitiesListSection(ctx.preferedLang)}
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default ActivitiesList;
