import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../Styles/PlaceSuggesions.module.css';

const PlaceSuggesionCards = (props) => {
  const [smallScreen, setSmallScreen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (width < 1000) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [width]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <Link
      to={props.url}
      className={
        !smallScreen
          ? styles.suggestionContainer
          : styles.suggestionContainerForSmallScreens
      }
    >
      <div className={styles.mediaContainer}>
        <img
          src={props.backgroundImage}
          alt='Suggestions background img'
          className={styles.offerImage}
        />
        <video
          className={styles.offerVideo}
          muted
          autoPlay={'autoplay'}
          preload='auto'
          loop
          src={props.backgroundVideo}
        ></video>
        <span className={styles.offerText}>{props.children}</span>
      </div>
      <div className={styles.hoverEffectContainer}>
        <img
          src='../../Assets/Icons/Division_Backgrounds/WhiteBg.svg'
          alt='Page packground div img'
          className={styles.whiteBackground}
        />
      </div>
    </Link>
  );
};

export default PlaceSuggesionCards;
