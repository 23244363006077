import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const GoogleMaps = (props) => {
  const OPTIONS = {
    minZoom: 4,
    maxZoom: 17,
  }
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDYp1te-bQEhWE9P9yehRE3biB7LpSEh4U"
    >
    <GoogleMap
    options = {OPTIONS}
      onLoad={props.onLoad}
      mapContainerStyle={props.mapContainerStyle}
      onClick={props.onClick}
      zoom={props.zoom}
      center={props.center}
    >
      {props.children}
    </GoogleMap>
    </LoadScript>
  );
}
export default GoogleMaps;
