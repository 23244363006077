import React from "react"
import { Link } from "react-router-dom"
import styles from "../../Styles/NavBarComponent.module.css"

const NavBarComponent = (props) => {
    return (
        <Link className={styles.navBarLink} to={props.url} onClick={props.onClick}>
            <li className={styles.navBarComponent}>{props.logo}<span>{props.children}</span></li>
        </Link>
    )
}

export default NavBarComponent