export const Languages = [
  {
    title: "en",
    image: "../../Assets/Icons/Translation/Gb_Flag.png",
  },
  {
    title: "hy",
    image: "../../Assets/Icons/Translation/Ra_Flag.png",
  },
  {
    title: "ru",
    image: "../../Assets/Icons/Translation/Rf_Flag.png",
  },
];
