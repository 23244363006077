import React, { useEffect, useState } from 'react';
import LangContext from '../Store/LangContext';
import LoadingSpinner from '../Components/Spinner/LoadingSpinner';
import HomePageBackgroundImage from '../Components/Home_Page_Components/HomePageBackgroundImage';
import HomePageSuggestions from '../Components/Home_Page_Components/HomePageSuggestions';
import HomePageActivities from '../Components/Home_Page_Components/HomePageActivities';
import HomePageAttractions from '../Components/Home_Page_Components/HomePageAttractions';
import HomePageHotelsBlock from '../Components/Home_Page_Components/HomePageHotelsBlock';
import keyWordsLocalization from '../Localization/Key_Words';
import styles from '../Styles/Home.module.css';
import MetaTitle from '../Components/Meta/MetaTitle';
import { loadContent } from '../Service/ApiService';

const Home = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await loadContent('HomePage', 'HomePageTexts');
      if (fetchData) setData(fetchedData);
    };
    fetchData();
  }, []);
  if (data?.length === 0) return <LoadingSpinner />;

  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <>
            <MetaTitle
              title={keyWordsLocalization.appTitle[ctx.preferedLang]}
              name='description'
              content={keyWordsLocalization.appTitle[ctx.preferedLang]}
              isMAinPage={true}
              rel={'canonical'}
              pageURL={'/en'}
            />
            <HomePageBackgroundImage data={data} />
            <HomePageSuggestions data={data} />
            <div className={styles.blockDivisionContainer}>
              <img
                src='../../Assets/Icons/Division_Backgrounds/DivisionGrey.svg'
                alt='Division Grey img'
                className={styles.blockDivisionGrey}
              />
            </div>
            <HomePageActivities data={data} />
            <div className={styles.blockDivisionContainer}>
              <img
                src='../Assets/Icons/Division_Backgrounds/DivisionGreen.svg'
                alt='Division Green img'
                className={styles.blockDivisionGreen}
              />
            </div>
            <HomePageAttractions data={data} />
            <div className={styles.blockDivisionContainer}>
              <img
                src='../Assets/Icons/Division_Backgrounds/Wave.svg'
                alt='Wave img'
                className={styles.blockDivisionWave}
              />
            </div>
            <HomePageHotelsBlock data={data} />
          </>
        );
      }}
    </LangContext.Consumer>
  );
};

export default Home;
