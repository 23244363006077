import React from 'react';
import LangContext from '../../Store/LangContext';
import HomePageAttractionsList from './HomePageAttractionsList';
import { nanoid } from 'nanoid';
import styles from '../../Styles/Home.module.css';

const HomePageAttractions = (props) => {
  return (
    <LangContext.Consumer>
      {(ctx) => {
        return (
          <div className={styles.attractionsContainer}>
            <div className={styles.blockTitleContainer}>
              <div className={styles.activityTextContainer}>
                {props.data.historyTitle?.map((item) => {
                  if (item.type === 'mainTitle') {
                    return (
                      <h1 className={styles.blockTitle} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </h1>
                    );
                  } else if (item.type === 'secondaryTitle') {
                    return (
                      <h2
                        className={`${styles.blockTitle} ${styles.whiteColor}`}
                        key={nanoid()}
                      >
                        {item.text[ctx.preferedLang]}
                      </h2>
                    );
                  } else {
                    return (
                      <span className={styles.blockTitle} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </span>
                    );
                  }
                })}
              </div>
              <br />
              <div className={styles.w100}>
                {props.data.history?.map((item) => {
                  if (item.type === 'mainTitle') {
                    return (
                      <h1 className={styles.shortInformation} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </h1>
                    );
                  } else if (item.type === 'secondaryTitle') {
                    return (
                      <h2
                        className={`${styles.shortInformation} ${styles.whiteColor}`}
                        key={nanoid()}
                      >
                        {item.text[ctx.preferedLang]}
                      </h2>
                    );
                  } else {
                    return (
                      <span className={styles.shortInformation} key={nanoid()}>
                        {item.text[ctx.preferedLang]}
                      </span>
                    );
                  }
                })}
              </div>
            </div>
            <div className={styles.marginTop30}>
              <HomePageAttractionsList />
            </div>
          </div>
        );
      }}
    </LangContext.Consumer>
  );
};

export default HomePageAttractions;
